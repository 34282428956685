import React from 'react'
import { graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import Layout from '~components/layout'
import SEO from '~components/seo'

const Sitemap = props => {
  const { section, locale } = props.pageContext
  const key = locale.toLowerCase()
  const { location } = props

  const sitemap = props.data
  const { services, topics, activities, articles, books, videos, other } = sitemap
  const sectionClasses = 'font-bold text-lg mt-8'
  const linkClasses = 'mt-2 text-blue hover:text-cyan hover:underline'

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO lang={locale} title='Site Map' />
      <div className='max-w-page pt-4 lg:py-8'>
        <div className='headline-1'>
          <FormattedMessage id='site-map' />
        </div>
        <div className='mt-16'>
          <div className={sectionClasses}>Home</div>
          <div>
            <Link to={`/${key}/`} className={linkClasses}>
              Home
            </Link>
          </div>

          <div className={sectionClasses}>About</div>
          <div>
            <Link to={`/${key}/about/`} className={linkClasses}>
              About
            </Link>
          </div>

          <div className={sectionClasses}>Services</div>
          {services.nodes.map(({ title, slug }, index) => (
            <div key={index}>
              <Link to={`/${key}/services/${slug}/`} className={linkClasses}>
                {title}
              </Link>
            </div>
          ))}

          <div className={sectionClasses}>Topics</div>
          {topics.nodes.map(({ title, slug }, index) => (
            <div key={index}>
              <Link to={`/${key}/topics/${slug}/`} className={linkClasses}>
                {title}
              </Link>
            </div>
          ))}

          <div className={sectionClasses}>Articles</div>
          {articles.nodes.map(({ title, slug }, index) => (
            <div key={index}>
              <Link to={`/${key}/articles/${slug}/`} className={linkClasses}>
                {title}
              </Link>
            </div>
          ))}

          <div className={sectionClasses}>Books</div>
          {books.nodes.map(({ title, slug }, index) => (
            <div key={index}>
              <Link to={`/${key}/books/${slug}/`} className={linkClasses}>
                {title}
              </Link>
            </div>
          ))}

          <div className={sectionClasses}>Activities</div>
          {activities.nodes.map(({ title, slug }, index) => (
            <div key={index}>
              <Link to={`/${key}/activities/${slug}/`} className={linkClasses}>
                {title}
              </Link>
            </div>
          ))}

          <div className={sectionClasses}>Videos</div>
          {videos.nodes.map(({ title, slug }, index) => (
            <div key={index}>
              <Link to={`/${key}/videos/${slug}/`} className={linkClasses}>
                {title}
              </Link>
            </div>
          ))}

          <div className={sectionClasses}>Other</div>
          {other.nodes.map(({ title, slug }, index) => (
            <div key={index}>
              <Link to={`/${key}/${slug}/`} className={linkClasses}>
                {title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Sitemap

export const sitemapQuery = graphql`
  query sitemapQuery($locale: String!) {
    services: allContentfulServiceDetailPage(filter: { node_locale: { eq: $locale } }, sort: { fields: name }) {
      nodes {
        title: name
        slug
      }
    }
    topics: allContentfulTopicPage(filter: { node_locale: { eq: $locale } }, sort: { fields: title }) {
      nodes {
        title
        slug
      }
    }
    activities: allContentfulActivityArticleBookOrVideoDetailPage(
      filter: { node_locale: { eq: $locale }, type: { eq: "Activity" } }
      sort: { fields: title }
    ) {
      nodes {
        title
        slug
      }
    }
    articles: allContentfulActivityArticleBookOrVideoDetailPage(
      filter: { node_locale: { eq: $locale }, type: { eq: "Article" } }
      sort: { fields: title }
    ) {
      nodes {
        title
        slug
      }
    }
    books: allContentfulActivityArticleBookOrVideoDetailPage(
      filter: { node_locale: { eq: $locale }, type: { eq: "Book" } }
      sort: { fields: title }
    ) {
      nodes {
        title
        slug
      }
    }
    videos: allContentfulActivityArticleBookOrVideoDetailPage(
      filter: { node_locale: { eq: $locale }, type: { eq: "Video" } }
      sort: { fields: title }
    ) {
      nodes {
        title
        slug
      }
    }
    other: allContentfulGenericPage(filter: { node_locale: { eq: $locale } }, sort: { fields: title }) {
      nodes {
        title
        slug
      }
    }
  }
`
